import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Ecard from '../components/Ecard'

const BestWishesPage = () => {
  return (
    <Layout>
      <SEO title="Season's greetings from Gasser Partner" />
      <Ecard />
    </Layout>
  )
}

export default BestWishesPage

export const i18n = {
  en: '/ecard',
  de: '/ecard',
}
